import type {
    CachedUsersCartData,
    CartMappedItem
} from '~/composables/types/api/cartConditions/carts-abstract';

export default defineStore('carts', () => {
    const {
        carts: loadedCarts,
        loadCartsAbstract,
        createCartAbstract,
        deleteCartAbstract,
        changeCartStatusAbstract,
        errorMessage,
    } = useCartsAbstracts();

    const { getUserData, userData } = useUser();

    const {
        getCachedData,
        updateCachedData,
        removeCachedData,
    } = useLocalStorageCache<CachedUsersCartData>(LocalStorageNameTypes.USER_ACTIVE_CART);

    const isLoading = ref(false);
    const loadCartsPromise = ref<Promise<void> | null>(null);
    const selectedCartId = ref<string | null>(null);

    const updateCachedCart = () => {
        if (userData.value?.id && selectedCartId?.value) {
            updateCachedData({
                cartId: selectedCartId.value,
                userId: userData.value.id,
            });
        }
    };

    const allLastCarts: ComputedRef<CartMappedItem[]> = computed(() => (
        [...loadedCarts.value].sort((cart1: CartMappedItem, cart2: CartMappedItem) => {
            const date1 = new Date(cart1.tempUpdatedAt);
            const date2 = new Date(cart2.tempUpdatedAt);

            return date2.getTime() - date1.getTime();
        })
    ));

    const lastCarts: ComputedRef<CartMappedItem[]> = computed(() => (
        allLastCarts.value.filter(cart => cart.state !== 'CLOSED')
    ));

    const carts: ComputedRef<CartMappedItem[]> = computed(() => (
        [...lastCarts.value].sort((cart1: CartMappedItem, cart2: CartMappedItem) => {
            if (!selectedCartId.value) {
                return 0;
            }

            return Number(cart2.id === selectedCartId.value) - Number(cart1.id === selectedCartId.value);
        })
    ));

    const activeCart: ComputedRef<CartMappedItem | undefined> = computed(() => {
        const cachedCart = getCachedData();
        let activeCartItem: CartMappedItem | undefined;

        if (!selectedCartId.value && cachedCart?.cartId && cachedCart?.userId === userData.value?.id) {
            const cartExists = carts.value.some(cart => cart.id === cachedCart.cartId);
            if (cartExists) {
                selectedCartId.value = cachedCart.cartId;
            }
        }

        if (selectedCartId.value) {
            activeCartItem = carts.value.find(cart => cart.id === selectedCartId.value);
            if (!activeCartItem) {
                selectedCartId.value = null;
            }
        }

        if (carts.value.length > 0 && !activeCartItem) {
            if (userData.value?.id) {
                updateCachedData({
                    cartId: carts.value?.[0]?.id,
                    userId: userData.value.id,
                });
            }
            activeCartItem = carts.value[0];
        }

        return activeCartItem;
    });

    const loadCarts = async () => {
        if (isLoading.value) {
            return loadCartsPromise.value;
        }

        isLoading.value = true;

        loadCartsPromise.value = new Promise(async (resolve, reject) => {
            try {
                await loadCartsAbstract();

                if (!userData.value?.id || userData.value?.id === '') {
                    await getUserData();
                }

                resolve();
            } catch (error) {
                reject(error);
            } finally {
                isLoading.value = false;
                loadCartsPromise.value = null;
            }
        });

        return loadCartsPromise.value;
    };

    const updateSelectedCart = (id: string | null) => {
        if (carts.value.some(cart => cart.id === id)) {
            selectedCartId.value = id;
            updateCachedCart();

            return;
        }

        const cachedCart = getCachedData();

        if (cachedCart && cachedCart?.cartId && cachedCart?.userId === userData.value?.id) {
            selectedCartId.value = cachedCart?.cartId;
        } else if (carts.value?.length === 0 || !userData.value?.id) {
            selectedCartId.value = id;
            updateCachedCart();
        }
    };

    const createCart = async (cartName: string) => {
        await createCartAbstract(cartName);
        removeCachedData();
        selectedCartId.value = null;
        updateCachedCart();
    };

    const deleteCart = async (cartId: string) => {
        await deleteCartAbstract(cartId);
    };

    const changeCartStatus = async (cartId: string, status: CartMappedItem['userFlowCartState']) => {
        await changeCartStatusAbstract(cartId, status);
    };

    return {
        carts,
        allLastCarts,
        lastCarts,
        activeCart,
        selectedCartId,
        isLoading,
        loadCarts,
        createCart,
        deleteCart,
        changeCartStatus,
        updateSelectedCart,
        errorMessage,
    };
});
